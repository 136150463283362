<template>
  <div class="container" v-loading="loading">
    <div class="content titbox">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>{{type==1?'The Terms of Use':'Privacy Policy'}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content htmlcententbox" v-if="type==1" v-html="list.usage"></div>
    <div class="content htmlcententbox" v-if="type==2" v-html="list.privacy"></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        list: '',
        type: this.$route.query.type,
        loading: false,
      }
    },
    created() {
      this.getdata()
    },
    methods: {
      getdata() {
        this.$get({
          url: "/app/auth/getPrivacy",
          success: (res) => {
            this.list = res.data
          },
          tip: () => { },
        })
      }
    },
  }
</script>

<style scoped lang="scss">
  .container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0;
    .titbox {
      width: 100%;
      /deep/.el-breadcrumb {
        font-size: 20px;
      }
      margin-bottom: 20px;
    }
    .content {
      width: 1000px;
      height: auto;
      color: #000;
      font-size: 16px;
      line-height: 1.8;
    }
    .htmlcententbox {
      padding: 0 30px;
    }
  }
</style>
